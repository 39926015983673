<template>
  <div class="vps-stats">
    <!--    <base-datepicker-filter-->
    <!--      v-if="serverId"-->
    <!--      :periods="periods"-->
    <!--      :period="period"-->
    <!--      :value="dateFilter"-->
    <!--      class="vps-stats__filter"-->
    <!--      @init="onFilterInit"-->
    <!--      @change="onFilterChange"-->
    <!--    />-->
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" />
      <div v-else class="vps-stats__content">
        <div class="vps-stats__grid l-flex-1_md-2">
          <div v-for="(item, key) in datasets" :key="key" class="l-col">
            <vps-stats-item
              :title="$t(`titles.${key}`)"
              :labels="labels"
              :datasets="item"
              :units="units[key]"
              :type="types[key]"
              class="vps-stats__item"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
import VpsStatsItem from '@/layouts/VPS/pages/Information/components/VpsStatsItem.vue';
import BaseDatepickerFilter from '@/components/BaseDatepicker/BaseDatepickerFilter.vue';
import { differenceInWeeks } from 'date-fns';
export default {
  name: 'ServerStat',
  components: {
    // BaseDatepickerFilter,
    VpsStatsItem,
    // MainCard,
  },
  mixins: [providerChange],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  data() {
    return {
      isLoading: false,
      socket: null,
      stats: {},
      dataRaw: [],
      dateFilter: { start: new Date(), end: new Date() },
      periods: ['week', 'month', 'quarter', 'year'],
      period: 'week',
      units: {
        vcpu: '%',
        bytes: 'B',
        packets: 'шт.',
        iops: 'шт.',
        ram: 'B',
        netBps: 'Bps',
        netPps: 'pps',
      },
      types: {
        vcpu: '%',
        filesystem: 'bytes',
        hdd: 'bytes',
        ram: 'bytes',
        netBps: 'bytes',
      },
    };
  },
  computed: {
    serverUuid() {
      return this.tariff.id;
    },
    serverId() {
      return this.$route.params.id;
    },
    token() {
      return localStorage.getItem('token');
    },
    datasets() {
      const dataset = {};
      Object.keys(this.stats).forEach(key => {
        if (key === 'vcpu') {
          dataset[key] = [
            {
              label: this.$t('labels.usage'),
              data: [],
            },
          ];
          this.stats[key].forEach(stat => {
            dataset[key][0].data.push(stat[1]);
          });
        }
        if (key === 'memory') {
          dataset[key] = [];
          Object.keys(this.stats[key]).forEach(item => {
            dataset[key].push({
              label: item,
              data: [],
            });
            this.stats[key][item].forEach(stat => {
              dataset[key].find(x => x.label === item).data.push(stat[1]);
            });
          });
        }
        if (key === 'network' || key === 'block') {
          Object.keys(this.stats[key]).forEach(item => {
            Object.keys(this.stats[key][item]).forEach(net => {
              Object.keys(this.stats[key][item][net]).forEach(mac => {
                const netName = `${mac}-${item}`;
                if (dataset[netName]) {
                  dataset[netName].push({
                    label: net,
                    data: [],
                  });
                } else {
                  dataset[netName] = [
                    {
                      label: net,
                      data: [],
                    },
                  ];
                }
                this.stats[key][item][net][mac].forEach(i => {
                  dataset[netName].find(x => x.label === net).data.push(i[1]);
                });
              });
            });
          });
        }
      });
      // console.log(dataset);
      return dataset;
    },
    labels() {
      const labels = [];
      this.dataRaw.metrics.vcpu.forEach(item => {
        // console.log(item);
        labels.push(item[0]);
      });
      return labels;
    },
    // stats() {
    //   return this.dataRaw.metrics;
    // },
    retention() {
      let diff = differenceInWeeks(this.dateFilter.end, this.dateFilter.start);
      //if (diff <= 1) return '1w';
      /*else*/ if (diff <= 24) return '24w';
      else return 'inf';
    },
  },
  watch: {
    dataRaw(event) {
      console.log(event);
      if (event) this.stats = event.metrics;
    },
  },
  mounted() {
    this.socket = this.socketForStat(this.serverUuid, this.serverId);
    this.socket.onmessage = event => {
      this.dataRaw = JSON.parse(event.data);
    };
  },
  beforeDestroy() {
    this.socket.close();
  },
  methods: {
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
      // this.$router.push({ name: 'vpsMain' });
    },
    socketForStat(uuid, id) {
      return new WebSocket(
        `wss://bill.rusonyx.ru/extapi-os-statload/ws?uuid=${uuid}&auth=${this.token}&elid=${id}`
      );
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "os": "Источник",
      "name": "Тип конфигурации",
      "cpu": "vCPU",
      "ram": "Память"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 160px;
      margin: 0.5em 0.75rem;
    }
  }
}

.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
